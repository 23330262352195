<template>
    <div>
        <HeaderCard :loading="loading" tittle="SLA (Agendamentos concluídos)" @onLoad="load" />
        <Chart type="bar" :data="stackedData" :options="stackedOptions" class="h-15rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import moment from 'moment-timezone';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        userIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 45;
                        };
                    }
                }
            ]
        };
    },
    computed: {
        stackedData() {
            return {
                labels: this.dados
                    .map((item) => this.formatarData(item.quebra))
                    .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []),
                datasets: [
                    {
                        label: 'No Prazo',
                        data: this.dados.map((p) => {
                            if (p.dentroDoPrazo != 0) {
                                return p.dentroDoPrazo;
                            } else {
                                return null;
                            }
                        }),
                        porcentagem: this.dados.map((p) => {
                            if (p.dentroDoPrazo != 0) {
                                return p.porcentagemDentroDoPrazo;
                            } else {
                                return null;
                            }
                        }),
                        backgroundColor: ['#36A2EB'],
                        skipNull: true
                    },
                    {
                        label: 'Fora do Prazo',
                        data: this.dados.map((p) => {
                            if (p.foraDoPrazo != 0) {
                                return p.foraDoPrazo;
                            } else {
                                return null;
                            }
                        }),
                        porcentagem: this.dados.map((p) => {
                            if (p.foraDoPrazo != 0) {
                                return p.porcentagemForaDoPrazo;
                            } else {
                                return null;
                            }
                        }),
                        backgroundColor: ['#FF6384'],
                        skipNull: true
                    }
                ]
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                interaction: {
                    mode: 'nearest'
                },
                plugins: {
                    legend: {
                        labels: {
                            fontColor: '#495057'
                        }
                    },
                    datalabels: {
                        formatter: (_value, context) => {
                            const porcentagem = Number(context.dataset.porcentagem[context.dataIndex]).toFixed(0);
                            const quantidade = context.dataset.data[context.dataIndex];
                            if (porcentagem && quantidade) return [`${porcentagem}%`, quantidade];
                        },
                        anchor: 'end',
                        align: 'top',
                        labels: {
                            value: {
                                color: '#757575',
                                font: {
                                    size: 12
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                            drawBorder: false
                        }
                    }
                }
            };
        },
    },
    watch: {
        customerIds() {
            this.queryData();
        },
        userIds() {
            this.queryData();
        }
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-responsaveis/dentro-e-fora-dos-prazos', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            userIds: this.userIds?.length ? this.userIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YYYY';

            return moment.tz(data, 'UTC').format(formato);
        }
    }
};
</script>
