<template>
    <div class="card p-5">
        <HeaderPanel :tittle="'Produtividade individual'" :loading="loading" nomeTelaDoManual="relatorios-e-indicadores-produtividade"/>
        <div class="grid" :class="{ 'disabled': loading }">
            <div v-if="$checkPermission('indicadores_produtividade_individual_filtrar_por_outros_usuarios:view')" class="lg:col-8 col-12">
                <label for="txtFilterCliente" class="mb-1">Usuário</label>
                <MultiSelect v-model="usersSelectedId" :options="usersOptions" dataKey="id" optionLabel="nome"
                    placeholder="Selecione..." class="w-full" :filter="true" />
            </div>
            <div class="lg:col-4 col-12" :class="{'lg:col-12 col-12 pt-5': !$checkPermission('indicadores_produtividade_individual_filtrar_por_outros_usuarios:view')}">
                <div class="flex justify-content-end flex-row h-full">
                    <div class="flex align-items-end">
                        <Button type="button" label="Recarregar" class="h-3rem" @click="loadAllComponentes"
                            icon="pi pi-sync" loadingIcon="pi pi-sync pi-spin" :loading="loading" />
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-content">
            <div class="grid h-auto">
                <div
                    class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeAgendamentosAutomaticosChart ref="quantidadeAgendamentosAutomaticosChart"
                            :customerIds="customerIds" :userIds="userIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 md:pl-0 lg:pr-3 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <QuantidadeAgendamentosPorUsuarioChart ref="quantidadeAgendamentosPorUsuarioChart"
                            :customerIds="customerIds" :userIds="userIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-3 lg:pr-0 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 30rem">
                        <EmpresasAgendadasGrid ref="empresasAgendadasGrid" :customerIds="customerIds" :userIds="userIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 20rem">
                        <SlaChart ref="slaChart" :customerIds="customerIds" :userIds="userIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 20rem">
                        <QuantidadeAtendimentosForaDoPeriodoChart ref="quantidadeAtendimentosForaDoPeriodoChart"
                            :customerIds="customerIds" :userIds="userIds" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderPanel from '../components/HeaderPanel.vue';
import QuantidadeAgendamentosAutomaticosChart from './components/QuantidadeAgendamentosAutomaticosChart.vue';
import SlaChart from './components/SlaChart.vue';
import QuantidadeAtendimentosForaDoPeriodoChart from './components/QuantidadeAtendimentosForaDoPeriodoChart.vue';
import QuantidadeAgendamentosPorUsuarioChart from './components/QuantidadeAgendamentosPorUsuarioChart.vue';
import EmpresasAgendadasGrid from './components/EmpresasAgendadasGrid.vue';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import BaseService from "@/services/BaseService";

export default {
    components: {
        HeaderPanel,
        QuantidadeAgendamentosAutomaticosChart,
        QuantidadeAgendamentosPorUsuarioChart,
        EmpresasAgendadasGrid,
        SlaChart,
        QuantidadeAtendimentosForaDoPeriodoChart
    },
    data() {
        return {
            customerIds: [],
            userIds: [],
            usersSelectedId: [],
            usersOptions: null,
            loading: false,
        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
    },
    async mounted() {
        this.$service = new BaseService('/profissional_medico');
        await this.loadUsers();
    },
    watch: {
        currentCustomer(novoValor) {
            if (JSON.stringify(novoValor) !== JSON.stringify(this.customerIds)) {
                this.customerIds = this.currentCustomer;
                setTimeout(() => {
                    this.loadAllComponentes();
                }, 100);
            }
        },
        usersSelectedId() {
            this.userIds = this.usersSelectedId.map(d => d.userId);
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
    },
    methods: {
        async loadUsers() {
            const { data } = await this.$service.findAll({});
            this.usersOptions = data;
        },
        async loadAllComponentes() {
            this.loading = true;
            try {
                await Promise.all([
                    this.$refs.quantidadeAgendamentosAutomaticosChart.queryData(),
                    this.$refs.quantidadeAgendamentosPorUsuarioChart.queryData(),
                    this.$refs.empresasAgendadasGrid.queryData(),
                    this.$refs.slaChart.queryData(),
                    this.$refs.quantidadeAtendimentosForaDoPeriodoChart.queryData(),
                ]);
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'info', summary: 'Ops!', detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!', life: 3000 });
                this.loading = false;
            }
        }
    }
};
</script>
<style scoped>
.card {
    background: #ffffff;
    box-sizing: border-box;
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #F4F4F4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}

.disabled {
    pointer-events: none; 
    opacity: 0.5;
}
</style>
