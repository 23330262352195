<template>
    <div>
        <HeaderCard
            :loading="loading"
            tittle="Agendamentos realizados"
            @onLoad="load"
            legenda="Quantidade de solicitações no mês atrelado ao usuario responsável pelo agendamento."
        />
        <Chart type="bar" :data="stackedData" :options="stackedOptions" class="h-23rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import moment from 'moment-timezone';
import { uniq } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        userIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 45;
                        };
                    }
                }
            ]
        };
    },
    computed: {
        stackedData() {
            const labels = this.dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            const data = {
                labels: labels,
                datasets: uniq(
                    this.dados.map((d) => {
                        return {
                            label: d.userName,
                            data: this.getQuantidade(d.userResponsavelId),
                            backgroundColor: '',
                            barThickness: 38,
                            skipNull: true,
                            intersect: false
                        };
                    })
                )
            };
            data.datasets.forEach((d) => (d.backgroundColor = this.gerarCorHexRandomica()));
            return data;
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                interaction: {
                    mode: 'nearest'
                },
                plugins: {
                    legend: {
                        labels: {
                            fontColor: '#495057'
                        }
                    },
                    datalabels: {
                        formatter: (_value, context) => {
                            const quantidade = context.dataset.data[context.dataIndex];
                            return quantidade;
                        },
                        anchor: 'end',
                        align: 'top',
                        labels: {
                            value: {
                                color: '#757575',
                                font: {
                                    size: 12
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                            drawBorder: false
                        }
                    }
                }
            };
        }
    },
    methods: {
        getQuantidade(userResponsavelId) {
            const dados = this.dados.filter((p) => p.userResponsavelId == userResponsavelId);
            const arrayQuantidade = [];
            const labels = this.dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            const labelsDados = dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            for (const quebra of labels) {
                if (labelsDados.includes(quebra)) {
                    const dadoQuantidade = dados.find((p) => this.formatarData(p.quebra) == quebra).quantidade;
                    arrayQuantidade.push(dadoQuantidade);
                    continue;
                } else {
                    arrayQuantidade.push(null);
                    continue;
                }
            }
            return arrayQuantidade;
        },
        getPorcentagem(userResponsavelId) {
            const dados = this.dados.filter((p) => p.userResponsavelId == userResponsavelId);
            const arrayQuantidade = [];
            const labels = this.dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            const labelsDados = dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            for (const quebra of labels) {
                if (labelsDados.includes(quebra)) {
                    const dadoQuantidade = dados.find((p) => this.formatarData(p.quebra) == quebra).porcentagem;
                    arrayQuantidade.push(dadoQuantidade);
                    continue;
                } else {
                    arrayQuantidade.push(null);
                    continue;
                }
            }
            return arrayQuantidade;
        },
        gerarCorHexRandomica() {
            let hex = '#';
            const letrasHex = '0123456789ABCDEF';

            for (let i = 0; i < 6; i++) {
                hex += letrasHex[Math.floor(Math.random() * 16)];
            }
            return hex;
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/agendamentos/por-responsaveis', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            userIds: this.userIds?.length ? this.userIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YYYY';

            return moment.tz(data, 'UTC').format(formato);
        }
    }
};
</script>
